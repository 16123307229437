import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const resources = {
  en: {
    translation: {
      "create": "Create"
    }
  },
  fr: {
    translation: {
      "create": "créer"
    }
  }
};

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: `${process.env.REACT_APP_API_URL}/lg/translation/{{lng}}`
    }
  });

export default i18n;
