import * as types from "./actionTypes";
import { hideLoading, setError, setLoading } from "./commonActions";
import languageApi from "../../api/languageApi";

export function loadLanguagesSuccess(items) {
  return { type: types.LOAD_LANGUAGE_SUCCESS, payload: items };
}

export function loadLanguageSuccess(item) {
  return { type: types.LOAD_LANGUAGE_SUCCESS, payload: item };
}

export function createLanguageSuccess(item) {
  return { type: types.CREATE_LANGUAGE_SUCCESS, payload: item };
}

export function updateLanguageSuccess(item) {
  return { type: types.UPDATE_LANGUAGE_SUCCESS, payload: item };
}

export function deleteLanguageSuccess(item) {
  return { type: types.DELETE_LANGUAGE_SUCCESS, payload: item };
}

export function loadLanguages() {
  return async (dispatch) => {
    try {
      dispatch(setLoading());

      const result = await languageApi.getLanguages();      
      const items = result.data;
      if (result.status === 200) dispatch(loadLanguagesSuccess(items));
      else dispatch(setError(result));
      dispatch(hideLoading());
      return items;
    } catch (error) {
      dispatch(setError(error));
      dispatch(hideLoading());
    }
  };
}

export function loadLanguage(id) {
  return async (dispatch) => {
    try {
      dispatch(setLoading());

      const result = await languageApi.getLanguage(id);
      const item = result.data;
      if (result.status === 200) {
        // dispatch(loadLanguageSuccess(item));
        dispatch(hideLoading());
        return item;
      }      
      else dispatch(setError(result));
      dispatch(hideLoading());
      
    } catch (error) {
      dispatch(setError(error));
      dispatch(hideLoading());
    }
  };
}

export function saveLanguage(language) {
  return async (dispatch) => {
    try {
      const result = await languageApi.saveLanguage(language);
      if (result.status === 200 || result.status === 201) {
        if (!language._id) {
          dispatch(createLanguageSuccess(result.data));
        } else {
          dispatch(updateLanguageSuccess(result.data));
        }
      } else {
        dispatch(setError(result));
        return { success: false, error: result };
      }

      return { success: true, error: "", data: result.data };
    } catch (error) {
      return { success: false, error: error.response.data.message };
    }
  };
}

export function deleteLanguage(language) {
  return async (dispatch) => {
    try {
      const result = await languageApi.deleteLanguage(language._id);
      if (result.status === 200) {
        dispatch(deleteLanguageSuccess(language));
      } else {
        dispatch(setError(result.data));
        return { success: false, error: result };
      }
      return { success: true, error: "", data: result.data };
    } catch (error) {
      return { success: false, error: error.response.data.message };
    }
  };
}

export function setCurrentLanguage(language) {
  return async (dispatch) => {
    dispatch({ type: types.SET_CURRENT_LANGUAGE, payload: language });
  };
}
