import * as types from "../actions/actionTypes";

const initialState = {
  languages: [],
  // currentLanguage: "en-US",
  currentLanguage: "FR"
};

export default function tagReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_LANGUAGE_SUCCESS:
      return {
        ...state,
        languages: action.payload
      };
    case types.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload
      };
    
    default:
      return state;
  }
}
