import { Apps, PeopleAltOutlined, SettingsOutlined, BarChartOutlined, PersonOutline, ManageAccounts, ManageAccountsOutlined, Widgets } from "@mui/icons-material";
import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useSelector } from "react-redux";

import config from "../../config";
import { GroupType } from "../../shared/enum";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    zIndex: 2
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  menuItem: {
    width: 200,
    display: "flex",
    justifyContent: "space-between"
  }
}));

const AppsMenu = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const params = new URLSearchParams(window.location.search);
  const currentUser = useSelector(state => state.auth.currentUser);
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    
    setOpen(false);
  };
  
  const redirect = (url) => {
    let token = params.get("token") || localStorage.getItem("token");
    
    if (!token) {
      // window.location.href = config.IDENTITY_APP_URL;
      window.open(config.IDENTITY_APP_URL, '_blank');
    }
    
    // window.location.href = url + `/?token=${token}`;
    window.open(url + `/?token=${token}`, '_blank');
  }
  
  const isVaildGroup = (group) => {
    return !!currentUser?.groups.some(g => g.type === group)
  }

  const redirectDashboard = (url) => {
    let token = params.get("token") || localStorage.getItem("token");

    if (!token) {
      // window.location.href = config.IDENTITY_APP_URL;
      window.open(config.IDENTITY_APP_URL, '_blank');
    }//

    // window.location.href = url + `/?token=${token}`;
    window.open(url + `?token=${token}`, '_blank');
  };
 
  
  return (
    <div className={classes.root}>
      <IconButton
        ref={anchorRef}
        size="small"
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle} sx={{color:'#f3e5f5'}}
      >
        <Widgets />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "right bottom"
            }}
          >
            <Paper sx={{ mt: 0.5, border:'1px solid #4dabf5' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow">
                {currentUser && currentUser.groups.find(x=>x.type===GroupType.Admin) && (
                  <MenuItem
                    className={classes.menuItem}
                    disabled={!isVaildGroup(GroupType.Admin)}
                    onClick={() => redirect(config.ADMIN_SITE_URL+ "/dashboard")}
                  >
                    <Typography variant="body2">Admin</Typography>
                    <ManageAccountsOutlined color="primary" />
                  </MenuItem>
                )}
                 {currentUser && currentUser.groups.find(x=>x.type===GroupType.Contact) && (
                  <MenuItem
                    className={classes.menuItem}
                    disabled={!isVaildGroup(GroupType.Contact)}
                    onClick={() => redirect(config.CONTACT_SITE_URL+"/home")}
                  >
                   <Typography variant="body2">Contact</Typography>
                    <PersonOutline color="primary" />
                  </MenuItem>
                 )}
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => redirectDashboard(`${config.DASHBOARD_SITE_URL}/home`)}
                  >
                    <Typography variant="body2">Dashboard</Typography>
                    <BarChartOutlined color="primary" />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AppsMenu;
