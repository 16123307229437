import { combineReducers } from "redux";
import auth from "./authReducer";
import error from "./errorReducer";
import settings from "./settingReducer";
import languages from "./languageReducer";
const rootReducer = combineReducers({
  languages,
  error,
  auth,
  settings
});

export default rootReducer;
