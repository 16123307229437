import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Button, Stack, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/loadingSpinner";

const LoginPage = () => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  if (keycloak && !keycloak.authenticated) {
    keycloak.clearToken();
    navigate("/login");
  }

const handleLogin = async () => {
 
  console.log('start login :>>', );
  const result = await keycloak.login();
  console.log('login :>>', result);
}
console.log('start login :>>',keycloak.authenticated );
  return (
    <Stack
      sx={{
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {keycloak && !keycloak.authenticated ? (
        
        <LoadingSpinner isLoading />
      ) : (
        <>
          <Box mb={2}>
            The user is {keycloak.authenticated ? "" : "NOT"} authenticated
          </Box>
          <Button variant="contained" onClick={() => {handleLogin()}}>
            Login
          </Button>
        </>
      )}
    </Stack>
  );
};

export default LoginPage;
