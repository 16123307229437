import { v4 as uuidv4 } from "uuid";

import request from "./requestConfig";

const prefix = "/lg";

export function getLanguages() {  
  return request.get(prefix);
}

export function getLanguage(id) {
  
  return request.get(`${prefix}/${id}`);
}

const deleteLanguage = (id) => {
  return request.delete(`${prefix}/${id}/`);
};

const saveLanguage = (entity) => {
  if (entity._id) {
    return request.put(`${prefix}/${entity._id}`, {
      ...entity
    });
  } else {
    // console.log("hey with entity : ", entity);
    return request.post(`${prefix}`, {
      ...entity,
      id: uuidv4()
    });
  }
};

export default { getLanguages, deleteLanguage, saveLanguage, getLanguage };
