import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpen from "@mui/icons-material/MenuOpen";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AppBar from "components/appBar";
import { Drawer, DrawerHeader } from "components/drawer";
import PropTypes from "prop-types";
import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";

import { Outlet, Link as RouterLink, useLocation } from "react-router-dom";
import { Logo, hasChildren, LogoNexa } from "shared/function";
import { breadcrumbNameMap, privateRouteList } from "shared/routes";
import UserMenu from "../userMenu";
import MultiLevel from "./MultiLevel";
import SingleLevel from "./SingleLevel";
import MenuList from "@mui/material/MenuList";
import { Paper, Stack } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";

import BgVectorImg from "../../assets/images/bg-vector-img.png";
import {backImg, backImg1, backImg2, backImg3, backImg4} from "../../assets/images"
import { getLogo } from "../../redux/actions/settingActions";
import AppsMenu from "components/appsMenu";
import LanguageSwitch from "components/languageSwitch";

const settings = ["Profile", "Account", "Dashboard", "Logout"];

export const MenuItems = ({ item }) => {
  const Component =
    hasChildren(item) && item.category ? MultiLevel : SingleLevel;

  return <Component item={item} />;
};

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const Layout = () => {
  const theme = useTheme();
  const { keycloak } = useKeycloak();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [appLogo,setAppLogo]=useState(LogoNexa)
  const logo=useSelector((state)=>state?.logo)


  let location = useLocation();
  useEffect(() => {
    loadAppLogo()
  },[logo])


  const loadAppLogo = async () => {
    let imageUrl = null;
    
    if (logo !== undefined && logo.length > 0) {
      imageUrl = `${logo}`;
      if (imageUrl !== undefined && imageUrl !== null) {
        setAppLogo(imageUrl);
      }
    } else {
      try {
      const res = await dispatch ( getLogo()) ; // Assuming getLogo is an async function
        setAppLogo(res.data); // Assuming res contains the logo URL or data
      } catch (error) {
        console.error("Failed to load logo:", error);
      }
    }
  };
 
 
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (setting) => {
    setAnchorElUser(null);

    if (setting === "Logout") {
      localStorage.removeItem("token");
      keycloak.logout();
    }
  };

  const pathnames = location.pathname.split("/").filter((x) => x);
  const drawerWidth = 240;
  return (
    <Box
    sx={{
      height: "100vh",
      width: "100%",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      bgcolor: "#eeeeee",
      "&::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
       
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",

        backgroundImage: `url(${backImg3})`,
        opacity: 0.2 

        // backgroundImage: `url(${backImg4})`,
        // opacity: 0.6, 
      },
     
    }}
      // sx={{
      //   height: "100vh",
      //   position: "relative",
      //   display: "flex",
      //   flexDirection: "column",
      //   // bgcolor: "#eeeeee",
      //   overflow: "hidden",

      //   backgroundImage: `url(${backImg3})`,
      //   backgroundRepeat: "no-repeat",
      //   // backgroundClip: 'content-box'
       
      // }}
    >
      <AppBar
        position="absolute"
        sx={{
          width: "100%",
          height: "62px",
          bgcolor: "white",
          borderBottom: "1px solid #EAECF0",
          boxShadow: "none"
        }}
        open={open}
      >
        <Toolbar
          variant="dense"
          sx={{
            height: "100%",
            justifyContent: "space-between"
            // pr: "24px",
            // keep right padding when drawer closed
          }}
        >
          <Box
            component="img"
            //sx={{ height: 55, width:160, textAlign: "center", mt:1 }}
            sx={{
              height: 55, // Adjust the height as needed
              maxWidth: 160, // Optionally set a maximum width
              objectFit: "contain", // Ensure the image maintains its aspect ratio
              textAlign: "center",
              mt: 1,
            }}
            alt="Your logo."
            src={appLogo}
          />
 <Box sx={{display:'flex',justifyContent:'flex-end'}}>

            {/* <AppsMenu /> */}
          <LanguageSwitch />
        


         
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          width: "100%",
          mt: 9,
          pb: 0,
          minHeight: " calc(100vh - 130px) ",
          zIndex: 1,
          // border:'1px solid red'
        }}
      >
        <Outlet />
      </Box>
      <Paper
        sx={{
          background: "transparent",
          border: "none",
          pt: 1.5,
          pb: 0,
          bottom: 0,
          width: "100%",
          height: 40,
          mb: 0.1,
          justifyContent: "center",
          textAlign: "center",
          color: "#838D94",
          fontFamily: "DM Sans",
          fontSize: "20px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "normal",
          // border:'1px solid blue'
        }}
        component="footer"
        square
        variant="outlined"
      >
        <Typography sx={{textTransform:'none'}} variant="body2">Nexa.itsm @2024</Typography>
      </Paper>
      <Box
        sx={{
          position: "absolute",
          right: "0",
          zIndex: "0",
          width: "400px",
          top: "41px"
        }}
      >
        {/* <img
          style={{ width: "100%", height: "100vh" }}
          src={BgVectorImg}
          alt=""
        /> */}
      </Box>
    
    </Box>
  );
};

export default Layout;
